html {
    background-color: #ffffff;
    box-sizing: border-box;
}

body {
    font-family: 'Raleway';
    font-size: 18px;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .container {
        padding: 0 20px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}
@media (min-width: 1400px) {
    .container {
        max-width: 1200px;
    }
}

h1,
h2,
h3 {
    font-family: 'Roboto Serif';
}

h3 {
    font-weight: 800;
    font-size: 40px;
    line-height: 60px;
}

h3 span {
    font-weight: 400;
}

h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 45px;
    padding-top: 75px;
    padding-bottom: 50px;
}

h1 span {
    font-size: 72px;
    font-weight: 800;
}

h1 .thin {
    font-weight: 300;
}

h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 52px;
    line-height: 62px;
    margin-bottom: 55px;
    margin-top: 80px;
    text-align: center;
}

p {
    font-size: 18px;
    line-height: 30px;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

/* Banner */
.banner {
    background: #ffffff;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center;
    grid-template-columns: 0.8fr 2fr 0.7fr;
}
.banner .logo-box {
    background: #eaf2f8;
    border-radius: 10px;
}
.banner .logo-box a {
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 15px;
    color: #090909;
}
.banner .info {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7d7f82;
    position: relative;
    margin-left: 15px;
    background-color: transparent;
}
.banner .info:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #f27558;
}
.banner .domaner-btn {
    background: #f27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title {
        font-size: 22px;
        margin-bottom: 10px;
    }
    .banner .info {
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
@media only screen and (max-width: 940px) {
    .banner {
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a {
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title {
        line-height: 24px;
        text-align: center;
    }
    .banner .info {
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before {
        content: none;
    }
}
@media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
}

.site-logo {
    font-weight: bold;
    font-size: 18px;
    color: #040603;
}

.logo-wrapper {
    padding-top: 33px;
    padding-bottom: 22px;
    display: flex;
    justify-content: space-between;
}

.logo-wrapper a {
    display: flex;
    align-items: center;
    gap: 10px;
}

.navbar {
    display: flex;
    justify-content: space-between;
}

.navbar li a {
    padding-left: 90px;
    color: #000;
    font-weight: 600;
    transition: color 0.3s;
}

.navbar li a:hover {
    color: #8285fc;
}

.site-header-inner {
    display: grid;
    grid-template-columns: 50% 1fr;
    padding-top: 55px;
}

.header-image-bg {
    background-image: url(../image/hero-img.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
}

.header-description {
    margin-bottom: 40px;
    color: #2d455b;
    font-weight: 500;
}

#nft-card-option span {
    position: relative;
}
#nft-card-option span::before {
    position: absolute;
    content: '';
    width: 51px;
    height: 51px;
    background-color: #fab554;
    border-radius: 16px;
    z-index: -1;
    top: -27px;
    left: -25px;
}

.option {
    background: #ebf1fe;
    padding: 60px 20px;
    padding-left: 400px;
    margin-bottom: 40px;
    border-radius: 20px;
    box-sizing: border-box;
}

.option ul .li {
    line-height: 28px;
    color: #422d5b;
    padding-bottom: 25px;
    padding-left: 27px;
    position: relative;
}

.option ul .li:before {
    content: '';
    position: absolute;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: #fab554;
    top: 9px;
    left: 0;
}

.dot {
    padding-left: 35px;
    margin-top: 25px;
    position: relative;
}

.dot::before {
    position: absolute;
    content: '';
    width: 3px;
    height: 3px;
    border-radius: 3px;
    background-color: #422d5b;
    top: 12px;
    left: 17px;
}

.option {
    position: relative;
    z-index: 2;
}

.option::before {
    position: absolute;
    content: '';
    width: 296px;
    height: 270px;
    background-color: #fff;
    border-radius: 10px 100px;
    z-index: -1;
    left: 47px;
}

.option:nth-of-type(2):before {
    top: 100px;
}
.option:nth-of-type(3):before {
    top: 200px;
}

.option h3 {
    padding-bottom: 30px;
}

.buy::after {
    position: absolute;
    content: '';
    background-image: url(../image/buy.webp);
    width: 329px;
    height: 250px;
    background-size: contain;
    background-repeat: no-repeat;
    top: 144px;
    left: 37px;
}

.exchange::after {
    position: absolute;
    content: '';
    background-image: url(../image/exchange.webp);
    width: 329px;
    height: 250px;
    background-size: contain;
    background-repeat: no-repeat;
    top: 64px;
    left: 37px;
}

.sell::after {
    position: absolute;
    content: '';
    background-image: url(../image/sell.webp);
    width: 329px;
    height: 250px;
    background-size: contain;
    background-repeat: no-repeat;
    top: 212px;
    left: 37px;
}

.our-team {
    text-align: center;
    background: #434a5c;
    border-radius: 20px;
    padding: 1px 110px 43px;
    color: #fff;
}

.our-team p {
    margin-bottom: 40px;
}

.motto {
    background: linear-gradient(358.53deg, #353a47 -30.45%, rgba(53, 58, 71, 0) 123.51%);
    border-radius: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 30px;
    font-weight: 400;
    position: relative;
}

.motto::before {
    position: absolute;
    content: '';
    background-image: url(../image/star.png);
    width: 32px;
    height: 32px;
    background-size: contain;
    background-repeat: no-repeat;
    top: -16px;
    left: 50%;
}

.bold {
    font-weight: 800;
}

.contact {
    text-align: center;
    padding-bottom: 100px;
}

.contact p {
    padding-bottom: 80px;
}

.cta {
    padding: 30px 80px;
    background: #8285fc;
    box-shadow: 0px 4px 29px rgba(57, 76, 113, 0.2);
    border-radius: 81px;
    color: #fff;
    position: relative;
}

/*
.cta:hover::before {
    position: absolute;
    content: "Services under development";
    padding: 30px 20px;
    background-color: #0e0e1a;
    color: #fff;
    top: -100px;
    border-radius: 50px;
    left: 10px;
}

.cta:hover::after {
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    background-color: #0e0e1a;
    top: -30px;
    left: 50%;
    transform: rotate(45deg);

}
*/

footer .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 35px;
    background: #ebf1fe;
    border-radius: 20px 20px 0 0;
}

footer .copyright {
    color: #828c98;
    font-size: 14px;
}

.swiper-wrapper {
    display: initial;
}

.swiper-pagination {
    display: none;
}

.m-visible {
    display: none;
}

.m-hidden {
    display: initial;
}

.body-wrapper {
    overflow: hidden;
}

@media only screen and (max-width: 768px) {
    header,
    article,
    footer {
        padding: 0px 22px;
    }

    h1 {
        font-size: 24px;
        font-weight: 300;
        line-height: 26px;
        margin-bottom: 24px;
        text-align: center;
        padding-top: 0;
        padding-bottom: 0;
    }

    h1 span {
        font-size: 29px;
    }

    /* #nft-cards {
        display: none;
    } */

    h2 {
        font-size: 28px;
        line-height: 36px;
        text-align: center;
        margin-bottom: 100px;
        margin-top: 10px;
    }

    h3 {
        font-size: 26px;
        line-height: 34px;
    }

    .site-header-inner {
        grid-template-columns: 1fr;
    }

    .hero-content {
        order: 2;
    }

    .header-image-bg {
        display: none;
    }

    .mobile-hero {
        width: 300px;
        height: 320px;
        position: relative;
        margin: 0 auto;
    }

    .mobile-hero::before {
        position: absolute;
        content: '';
        background-image: url(../image/hero-img.png);
        width: 374px;
        height: 295px;
        background-size: contain;
        background-repeat: no-repeat;
        right: -95px;
    }

    .navbar {
        display: none;
    }

    .logo-wrapper {
        justify-content: center;
        padding-top: 0;
    }

    .logo-wrapper a {
        justify-content: center;
    }

    .logo {
        width: 100%;
        background-color: #ebf1fe;
        padding: 20px;
        border-radius: 0 0 10px 10px;
    }

    .swiper-wrapper {
        box-sizing: border-box !important;
        display: flex;
    }

    .swiper-pagination-bullet-active {
        background-color: #8285fc;
    }

    .swiper-pagination-bullet {
        color: transparent;
    }

    .swiper {
        overflow: visible;
    }

    .our-team {
        margin-top: 20px;
        padding: 1px 40px 43px;
    }

    .our-team h2 {
        margin-bottom: 35px;
        margin-top: 55px;
    }

    .contact h2 {
        margin-bottom: 35px;
        margin-top: 55px;
    }

    .swiper-pagination {
        position: initial;
        padding-top: 9px;
        display: block;
    }

    .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
    .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 10px;
    }

    .option {
        padding-left: 40px;
    }

    .option::before {
        content: none;
    }

    .exchange::after {
        width: 181px;
        height: 140px;
        top: -69px;
    }

    .option h3 {
        padding-top: 25px;
    }

    .buy::after {
        width: 200px;
        height: 120px;
        top: -55px;
    }

    .sell::after {
        width: 200px;
        height: 140px;
        top: -75px;
    }

    .mobile-detector {
        display: none;
    }

    .m-visible {
        display: initial;
    }

    .m-hidden {
        display: none;
    }

    .header-image {
        display: none;
    }

    .site-header-inner {
        padding: 26px 20px 28px;
    }

    .header-description {
        margin-bottom: 26px;
    }

    .nav-wrapper p {
        margin-bottom: 20px;
    }

    .nav-wrapper ul {
        display: block;
    }

    .nav-wrapper ul li:not(:last-child) {
        margin-bottom: 16px;
    }

    .nav-wrapper li {
        width: 100%;
    }

    footer .container {
        flex-direction: column;
        padding-bottom: 24px;
        padding-top: 20px;
    }

    footer .logo-wrapper {
        padding-bottom: 8px;
    }
}

@media only screen and (max-width: 380px) {
    .site-header-inner {
        padding: 15px 1px 28px !important;
    }
    header {
        padding: 0 10px;
    }
}
